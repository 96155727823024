import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../services/parsers';
import api from '../../services/api';
import callEvent from '../../services/events';
import { successToast } from '../../services/toast';
import { DISPLAY_ENTER_ENV_DATA_MODAL, DISPLAY_ENTER_SUBSCRIPTION_DATA_MODAL, DISPLAY_CANCEL_SUBSCRIPTION_MODAL } from '../../constants';

class CustomerInfoSection extends React.Component {
  constructor(props) {
    super(props);

    this.handleEditSubscriptionInfo = this.handleEditSubscriptionInfo.bind(this);
    this.handleCancelSubscription = this.handleCancelSubscription.bind(this);
    this.handleFetchEnvData = this.handleFetchEnvData.bind(this);
    this.handleEnterEnvData = this.handleEnterEnvData.bind(this);
    this.handleResendActivationEmail = this.handleResendActivationEmail.bind(this);
    this.handleSelectQuestionnaire = this.handleSelectQuestionnaire.bind(this);
    this.handleSelectOrder = this.handleSelectOrder.bind(this);

    this.state = {
      customerInfo: props.customerInfo,
      productIssue: (props.customerInfo.orders_with_issue || {}).last_order_issue || {},
    };
  }

  handleEditSubscriptionInfo() {
    const { customerInfo } = this.state;

    callEvent(DISPLAY_ENTER_SUBSCRIPTION_DATA_MODAL, {
      additionalData: customerInfo.customer.subscription,
      handleSubmit: (data) => {
        api.customers.updateSubscriptionData({ ...data, id: customerInfo.customer.id })
          .then((res) => {
            this.setState({
              customerInfo: { ...customerInfo, customer: { ...customerInfo.customer, subscription: res.subscription } },
            });
          });
      },
    });
  }

  handleCancelSubscription() {
    const { customerInfo } = this.state;
    const { subscriptionCancelSubmit } = this.props;

    callEvent(DISPLAY_CANCEL_SUBSCRIPTION_MODAL, {
      handleSubmit: (data) => { api.customers.cancelSubscription({ ...data, id: customerInfo.customer.id }).then(subscriptionCancelSubmit); },
    });
  }

  handleFetchEnvData() {
    const { customerInfo } = this.state;
    const { handleAfterCustomerInfoUpdate } = this.props;

    api.environmentData.fetchLatest({ customer_id: customerInfo.customer.id })
      .then((res) => {
        const newCustomerInfo = { ...customerInfo, env_data: res };
        this.setState({ customerInfo: newCustomerInfo });
        handleAfterCustomerInfoUpdate(newCustomerInfo);
      });
  }

  handleEnterEnvData() {
    const { customerInfo } = this.state;
    const { handleAfterCustomerInfoUpdate } = this.props;

    callEvent(DISPLAY_ENTER_ENV_DATA_MODAL, {
      handleSubmit: (data) => {
        api.environmentData.create({ ...data, customer_id: customerInfo.customer.id })
          .then((res) => {
            const newCustomerInfo = { ...customerInfo, env_data: res };
            this.setState({ customerInfo: newCustomerInfo });
            handleAfterCustomerInfoUpdate(newCustomerInfo);
          });
      },
    });
  }

  handleResendActivationEmail() {
    const { customerInfo } = this.state;
    api.customers.resendInvite({ id: customerInfo.customer.id })
      .then((res) => { if (res.ok) successToast('Success'); });
  }

  handleSelectQuestionnaire(e) {
    const { value } = e.target;
    const { customerInfo } = this.state;

    api.customers.fetchDataForQuestionaire({ questionnaire_id: value })
      .then((res) => {
        this.setState({
          customerInfo: { ...customerInfo, questionnaire: res.questionnaire },
        });
      });
  }

  handleSelectOrder(e) {
    const { value } = e.target;

    api.orders.fetchProductIssue({ shopify_number: value })
      .then((res) => {
        this.setState({
          productIssue: res.product_issue,
        });
      });
  }

  renderCancelQuestionnaireTooExpensive() {
    const { customerInfo } = this.state;
    const results = customerInfo.customer.last_canceled_subscription;
    return (
      <>
        <div>{`Product Worth: ${(results.product_worth || []).join(', ')}`}</div>
        <div>{`Test Kit on its own: ${results.test_kit_own ? 'Yes' : 'No'}`}</div>
      </>
    );
  }

  renderCancelQuestionnairePoorExperience() {
    const { customerInfo } = this.state;
    const results = customerInfo.customer.last_canceled_subscription;
    return (
      <>
        <div>{`Under expectations: ${(results.under_expectations || []).join(', ')}`}</div>
        { (results.under_expectations || []).includes('test_kit') && <div>{`Test Kit under expectations: ${(results.test_kit_under_expectations || []).join(', ')}`}</div>}
        { (results.under_expectations || []).includes('fragrance') && (
          <>
            <div>{`Likes fragrance: ${results.likes_fragrance ? 'Yes' : 'No'}`}</div>
            <div>{`Fragrance under expectations: ${(results.fragrance_under_expectations || []).join(', ')}`}</div>
            <div>{`Fragrance under expectations (other): ${results.fragrance_under_expectations_other}`}</div>
          </>
        )}
      </>
    );
  }

  renderCancelQuestionnaireFoundBetter() {
    const { customerInfo } = this.state;
    const results = customerInfo.customer.last_canceled_subscription;
    return (
      <>
        <div>{`Switched to: ${results.switched_products}`}</div>
        <div>{`Switch reason: ${(results.switched_products_reason || []).join(', ')}`}</div>
        <div>{`Switch reason (other): ${results.switched_products_reason_other}`}</div>
      </>
    );
  }

  renderCancelQuestionnaire() {
    const { customerInfo } = this.state;
    const results = customerInfo.customer.last_canceled_subscription;
    return (
      <>
        <div>{`Cancel reason: ${(results.cancel_reason || []).join(', ')}`}</div>
        <div>{`Cancel reason (other): ${results.cancel_reason_other}`}</div>
        { (results.cancel_reason || []).includes('too_expensive') && this.renderCancelQuestionnaireTooExpensive() }
        { (results.cancel_reason || []).includes('poor_experience') && this.renderCancelQuestionnairePoorExperience() }
        { (results.cancel_reason || []).includes('found_better') && this.renderCancelQuestionnaireFoundBetter() }
        <div>{`Recommendation: ${results.recommendation}`}</div>
      </>
    );
  }

  render() {
    const { customerInfo, productIssue } = this.state;
    const {
      subscriptionType, shouldRenderRecommendedCocktails, shouldRenderCurrentQuestionnaire, shouldRenderProductIssues,
      shouldRenderSubscriptionInfo,
    } = this.props;
    const subProp = subscriptionType === 'Active' ? 'subscription' : 'last_canceled_subscription';

    return (
      <>
        <div className="form-group custom-row">
          <div style={{ width: '50%' }}>
            {shouldRenderSubscriptionInfo && (
              <div className="mb-5">
                <div style={{ fontWeight: 'bold' }}>Subscription info:</div>
                <div>{`SH qty: ${customerInfo.customer[subProp].sh_qty}`}</div>
                <div>{`CO qty: ${customerInfo.customer[subProp].co_qty}`}</div>
                <div>{`Discount: ${customerInfo.customer[subProp].discount}%`}</div>
                <div>{`Period (months): ${customerInfo.customer[subProp].period}`}</div>
                <div>{`Reuse pumps: ${customerInfo.customer[subProp].reuse_pumps}`}</div>
                <div>{`Created: ${formatDate(new Date(customerInfo.customer[subProp].created_at))}`}</div>
                <div>{`Updated: ${formatDate(new Date(customerInfo.customer[subProp].created_at))}`}</div>
                <div>{`Next delivery date: ${formatDate(new Date(customerInfo.customer[subProp].next_billing_date))}`}</div>
                {subscriptionType === 'Active' && (
                  <>
                    <button type="button" id="edit-subscription" className="btn btn-secondary mt-4" onClick={this.handleEditSubscriptionInfo}>Update</button>
                    <button type="button" id="cancel-subscription" className="btn btn-danger mt-4 ml-4" onClick={this.handleCancelSubscription}>Cancel</button>
                  </>
                )}
                {subscriptionType !== 'Active' && this.renderCancelQuestionnaire()}
              </div>
            )}

            <div>
              <div style={{ fontWeight: 'bold' }}>Customer info:</div>
              <div>{`Email: ${customerInfo.customer.email}`}</div>
              <div>{`First Name: ${customerInfo.customer.first_name}`}</div>
              <div>{`Last Name: ${customerInfo.customer.last_name}`}</div>
              {shouldRenderProductIssues && (
                <div>
                  <span style={{ color: customerInfo.product_issues_count > 0 ? 'red' : 'inherit' }}>
                    {`${customerInfo.product_issues_count} of orders with Product Issues`}
                  </span>
                </div>
              )}
            </div>

            {Object.keys(customerInfo.report_data).length && (
              <div className="mt-5">
                <div style={{ fontWeight: 'bold' }}>Customer report data:</div>
                <div>{`Protein analysis score: ${customerInfo.report_data.protein_analysis_score}/10.0`}</div>
                <div>{`Microscopy score: ${customerInfo.report_data.calculated_microscopic_score}/10.0 (${customerInfo.report_data.microscopic_score}/4)`}</div>
                <div>{`Hair condition score: ${customerInfo.report_data.hair_condition_value}/10.0 (${customerInfo.report_data.hair_condition_label})`}</div>
                <div>{`Hair texture: ${customerInfo.report_data.hair_diam}um (${customerInfo.report_data.hair_texture})`}</div>
              </div>
            )}

            <div className="mt-5">
              <div style={{ fontWeight: 'bold' }}>Customer environmental data:</div>
              <div>{`Zip code: ${customerInfo.questionnaire['Zip code']}`}</div>
              <div>{`Humidity: ${customerInfo.env_data.humidity}`}</div>
              <div>{`UV: ${customerInfo.env_data.uv}`}</div>
              <div>{`Air pollution: ${customerInfo.env_data.air_pollution}`}</div>
              <div>{`Water hardness: ${customerInfo.env_data.water_hardness}`}</div>
              <div>{`Last updated: ${formatDate(new Date(customerInfo.env_data.updated_at))}`}</div>
              <div>
                <button type="button" id="fetch-env-data" className="btn btn-secondary mt-4" onClick={this.handleFetchEnvData}>Fetch latest data</button>
                <button type="button" id="enter-env-data" className="btn btn-secondary mt-4 ml-4" onClick={this.handleEnterEnvData}>Enter new data</button>
              </div>
              <div>
                <button type="button" id="enter-env-data" className="btn btn-secondary mt-4" onClick={this.handleResendActivationEmail}>Resend Activation Email</button>
              </div>
            </div>

            <div className="mt-5">
              <div style={{ fontWeight: 'bold' }}>Cocktail params:</div>
              {Object.entries(customerInfo.recommended_cocktails.cocktail_params || {}).map((hash) => <div key={hash[0]}>{`${hash[0]}: ${hash[1]}`}</div>)}
            </div>
          </div>

          <div style={{ width: '50%' }}>
            {shouldRenderProductIssues && (
              <>
                {((customerInfo.orders_with_issue || {}).shopify_numbers || {}).length && (
                  <div className="form-group mb-5">
                    <label htmlFor="order-select">Current Order (for product issue):</label>
                    <select
                      className="common-select"
                      id="order-select"
                      name="order-select"
                      onChange={this.handleSelectOrder}
                    >
                      {customerInfo.orders_with_issue.shopify_numbers.map((item, index) => (
                        <option key={`${item}-${index}`} value={item}>{`Order #${item} ${formatDate(new Date(customerInfo.orders_with_issue.dates[index]))}`}</option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="form-group mb-5">
                  <div style={{ fontWeight: 'bold' }}>Product issues:</div>
                  <div>
                    {'Date: '}
                    <span style={{ color: 'red' }}>{(productIssue || {}).updated_at ? formatDate(new Date((productIssue || {}).updated_at)) : ''}</span>
                  </div>
                  <div>
                    {'Hair issues: '}
                    <span style={{ color: 'red' }}>{((productIssue || {}).hair_issues || []).join(', ')}</span>
                  </div>
                  <div>
                    {'Scalp issues: '}
                    <span style={{ color: 'red' }}>{((productIssue || {}).scalp_issues || []).join(', ')}</span>
                  </div>
                  <div>
                    {'Shampoo issues: '}
                    <span style={{ color: 'red' }}>{((productIssue || {}).shampoo_issues || []).join(', ')}</span>
                  </div>
                  <div>
                    {'Conditioner issues: '}
                    <span style={{ color: 'red' }}>{((productIssue || {}).conditioner_issues || []).join(', ')}</span>
                  </div>
                  <div>
                    {'Reformulation reasons: '}
                    <span style={{ color: 'red' }}>{((productIssue || {}).reformulation_reasons || []).join(', ')}</span>
                  </div>
                  <div>
                    {'Stars: '}
                    <span style={{ color: 'red' }}>{(productIssue || {}).stars}</span>
                  </div>
                  <div>
                    {'Feedback: '}
                    <span style={{ color: 'red' }}>{(productIssue || {}).feedback}</span>
                  </div>
                </div>
              </>
            )}
            {shouldRenderCurrentQuestionnaire && (
              <>
                <div className="form-group mb-5">
                  <label htmlFor="questionnaire-select">Current Questionnaire:</label>
                  <select
                    className="common-select"
                    id="questionnaire-select"
                    name="questionnaire-select"
                    onChange={this.handleSelectQuestionnaire}
                  >
                    {(customerInfo.questionnaire_dates || []).map((item) => (
                      <option key={item.id} value={item.id}>{`${formatDate(new Date(item.date))} (${item.id})`}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <div style={{ fontWeight: 'bold' }}>Customer questionnaire:</div>
                  {Object.entries(customerInfo.questionnaire).map((hash) => <div key={hash[0]}>{`${hash[0]}: ${hash[1]}`}</div>)}
                </div>
              </>
            )}
          </div>
        </div>

        {shouldRenderRecommendedCocktails && customerInfo.recommended_cocktails.report && (
          <div className="recommended-cocktails-report">
            <div className="recommended-cocktails-report-header">Customer recommended cocktails report:</div>
            <div className="form-group custom-row">
              <div>
                <div className="recommended-cocktails-report-header">SHAMPOO</div>
                <div className="recommended-cocktails-report-body" dangerouslySetInnerHTML={{ __html: customerInfo.recommended_cocktails.report.shampoo }} />
              </div>
              <div>
                <div className="recommended-cocktails-report-header">CONDITIONER</div>
                <div className="recommended-cocktails-report-body" dangerouslySetInnerHTML={{ __html: customerInfo.recommended_cocktails.report.conditioner }} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

CustomerInfoSection.propTypes = {
  customerInfo: PropTypes.instanceOf(Object).isRequired,
  subscriptionType: PropTypes.string,
  subscriptionCancelSubmit: PropTypes.func,
  shouldRenderRecommendedCocktails: PropTypes.bool,
  shouldRenderCurrentQuestionnaire: PropTypes.bool,
  shouldRenderProductIssues: PropTypes.bool,
  shouldRenderSubscriptionInfo: PropTypes.bool,
  handleAfterCustomerInfoUpdate: PropTypes.func,
};

CustomerInfoSection.defaultProps = {
  subscriptionType: 'Active',
  subscriptionCancelSubmit: () => {},
  shouldRenderRecommendedCocktails: false,
  shouldRenderCurrentQuestionnaire: false,
  shouldRenderProductIssues: false,
  shouldRenderSubscriptionInfo: false,
  handleAfterCustomerInfoUpdate: () => {},
};

export default CustomerInfoSection;
