import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import Pagination from '../customElements/Pagination';
import api from '../../services/api';

class RecordsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFetchRecords = this.handleFetchRecords.bind(this);
    this.generateCsvData = this.generateCsvData.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.state = {
      step: 1,
      records: props.records,
      currentRecord: null,
      currentTab: urlParams.get('tab') || 'web',
      searchText: urlParams.get('search') || '',
      lastSortKey: urlParams.get('order') || 'created_at desc',
      pagination: {
        web: {
          currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: props.isLastPage,
        },
        app: {
          currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: props.isLastPage,
        },
      },
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  handleTabChange(tab) {
    const { pagination } = this.state;
    const page = pagination[tab.toLowerCase()] && pagination[tab.toLowerCase()].currentPage ? pagination[tab.toLowerCase()].currentPage : 1;
    this.handleFetchRecords(page, tab);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  handleStepChange(val = 1) {
    const { step } = this.state;
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  getFields = () => {
    const { currentTab } = this.state;
    const appFields = [
      { name: 'optic_number', text: 'Optic Number' },
      { name: 'hair_measurement', text: 'Hair Measurement (mm)' },
      { name: 'sebum_measurement', text: 'Scalp Oil Measurement (xx/100)' },
      { name: 'original_image', text: 'Original Image' },
      { name: 'analyzed_image', text: 'Analyzed Image' },
      { name: 'filtered_image', text: 'Filtered Image' },
      { name: 'ppl_image', text: 'PPL Image' },
      { name: 'xpl_image', text: 'XPL Image' },
    ];

    return [
      { name: 'id', text: 'ID' },
      { name: 'email', text: 'Email' },
      { name: 'phone_number', text: 'Phone Number' },
      { name: 'first_name', text: 'First Name' },
      { name: 'last_name', text: 'Last Name' },
      { name: 'zip_code', text: 'Zip Code' },
      { name: 'has_subscribed', text: 'Has Subscribed' },
      { name: 'hair_texture', text: 'Hair Texture' },
      { name: 'scalp_oil_level', text: 'Scalp Oil Level' },
      { name: 'hair_type', text: 'Hair Type' },
      { name: 'treatments', text: 'Treatments' },
      { name: 'frizz', text: 'Frizz' },
      { name: 'breakage', text: 'Breakage' },
      { name: 'shine', text: 'Shine' },
      { name: 'needs', text: 'Needs' },
      { name: 'color', text: 'Color' },
      { name: 'natural_color', text: 'Natural Color' },
      { name: 'heard_from', text: 'Heard from' },
      { name: 'heard_from_other', text: 'Heard from (other)' },
      { name: 'initial_recommendations', text: 'Initial Recommendations' },
      { name: 'final_recommendations', text: 'Final Recommendations' },
      { name: 'uuid', text: 'UUID' },
      { name: 'created_at', text: 'Created' },
      { name: 'updated_at', text: 'Updated' },
      ...(currentTab && currentTab === 'app' ? appFields : []),
    ];
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchRecords(null, null, order);
  }

    handleChange = (name) => (event) => {
      this.setState({
        [name]: event.target.value,
      });
    };

    generateCsvData() {
      const { records, currentTab } = this.state;
      const recommendationsFields = ['initial_recommendations', 'final_recommendations'];
      const csvHeaders = ['ID', 'Email', 'Phone Number', 'First Name', 'Last Name', 'Zip Code', 'Has Subscribed', 'Hair Texture', 'Scalp Oil Level', 'Hair Type', 'Treatments', 'Frizz', 'Breakage', 'Shine', 'Needs', 'Color', 'Natural Color', 'Heard from', 'Heard from (other)', 'Initial Dose 1', 'Initial Dose 2', 'Initial Dose 3', 'Initial Shampoo', 'Initial Conditioner', 'Final Dose 1', 'Final Dose 2', 'Final Dose 3', 'Final Shampoo', 'Final Conditioner', 'UUID', 'Created', 'Updated'];
      if (currentTab === 'app') csvHeaders.push('Optic Number', 'Hair Measurement (mm)', 'Scalp Oil Measurement (xx/100)', 'Original Image', 'Analyzed Image', 'Filtered Image', 'PPL Image');

      return [
        csvHeaders,
        ...records.map((record) => {
          const array = [];
          this.getFields().map((field) => {
            if (recommendationsFields.includes(field.name)) {
              const doses = record[field.name].filter((type) => !type.endsWith('Shampoo') && !type.endsWith('Conditioner'));
              const shampoo = record[field.name].find((type) => type.endsWith('Shampoo'));
              const conditioner = record[field.name].find((type) => type.endsWith('Conditioner'));

              for (let i = 0; i < 3; i += 1) {
                array.push(doses[i] || '');
              }
              array.push(shampoo || '');
              array.push(conditioner || '');
              return true;
            }
            if (Array.isArray(record[field.name])) return array.push(record[field.name].join(','));
            if (['created_at', 'updated_at'].includes(field.name)) return array.push(new Date(record[field.name]).toLocaleString());
            if (field.name === 'has_subscribed') return array.push(record[field.name] ? 'Yes' : 'No');
            return array.push(record[field.name]);
          });
          return array;
        }),
      ];
    }

    handleRecordSelect(record) {
      this.setState({ currentRecord: record }, this.handleStepChange);
    }

    handleFetchRecords(newPage, newTab, newOrder) {
      const {
        pagination, lastSortKey, searchText, currentTab,
      } = this.state;
      const tab = newTab || currentTab;
      const page = newPage || (pagination[currentTab.toLowerCase()] ? pagination[currentTab.toLowerCase()].currentPage : 1);
      const order = newOrder || lastSortKey;
      window.history.pushState({}, null, `?tab=${tab}&page=${page}&order=${order}&search=${searchText}`);

      api.quizRecords.fetchRecords(tab, page, order, searchText)
        .then((res) => this.setState({
          records: res.records,
          lastSortKey: order,
          currentTab: tab,
          pagination: {
            ...pagination,
            [tab.toLowerCase()]: {
              ...pagination[tab.toLowerCase()],
              currentPage: page,
              isLastPage: res.is_last_page,
            },
          },
        }));
    }

    render() {
      const { canExport } = this.props;
      const {
        step, records, pagination, searchText, currentRecord, currentTab,
      } = this.state;

      return (
        <div className="card">
          <div className="card-body custom-form-block">
            <div className="custom-info-block search-container">
              <div>Quiz Records</div>
              <form
                className={`search-block ${currentRecord ? 'hidden' : ''}`}
                onSubmit={(e) => {
                  e.preventDefault();
                  this.handleFetchRecords();
                }}
              >
                <input
                  type="text"
                  defaultValue={searchText}
                  placeholder="Search..."
                  id="search"
                  onChange={this.handleChange('searchText')}
                />
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
            <div className="custom-stepper">
              <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
                1. Choose record
              </span>
              <span className="custom-stepper-arrow">
                {'>'}
              </span>
              <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
                2. Record details
              </span>
            </div>
            {step === 1 && (
              <>
                <div className="custom-tabs">
                  <div
                    id="tab-web"
                    onClick={() => this.handleTabChange('web')}
                    className={currentTab === 'web' ? 'active' : ''}
                  >
                    Quiz
                  </div>
                  <div
                    id="tab-app"
                    onClick={() => this.handleTabChange('app')}
                    className={currentTab === 'app' ? 'active' : ''}
                  >
                    App
                  </div>
                </div>
                { canExport && <CSVLink className="csv-download" data={this.generateCsvData()}>Export as CSV</CSVLink> }
                <div className="table-responsive">
                  <table className="table table-striped table-responsive-sm custom-table">
                    <thead>
                      <tr>
                        <th scope="col" onClick={() => this.handleSort('id')}>ID</th>
                        <th scope="col" style={{ minWidth: '12rem' }} onClick={() => this.handleSort('email')}>Email</th>
                        <th scope="col" style={{ minWidth: '12rem' }} onClick={() => this.handleSort('first_name')}>First Name</th>
                        <th scope="col" style={{ minWidth: '12rem' }} onClick={() => this.handleSort('last_name')}>Last Name</th>
                        <th scope="col" style={{ minWidth: '8rem' }} onClick={() => this.handleSort('zip_code')}>Zip Code</th>
                        <th scope="col" onClick={() => this.handleSort('has_subscribed')}>Has Subscribed</th>
                        <th scope="col" style={{ minWidth: '13rem' }} onClick={() => this.handleSort('created_at')}>Created</th>
                        <th scope="col" style={{ minWidth: '13rem' }} onClick={() => this.handleSort('updated_at')}>Updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.map((item) => (
                        <tr className="custom-tr-selectable" key={item.id} onClick={() => this.handleRecordSelect(item)}>
                          <th scope="row">{item.id}</th>
                          <td>{item.email || '-'}</td>
                          <td>{item.first_name || '-'}</td>
                          <td>{item.last_name || '-'}</td>
                          <td>{item.zip_code || '-'}</td>
                          <td>{item.has_subscribed ? 'Yes' : 'No'}</td>
                          <td>{new Date(item.created_at).toLocaleString()}</td>
                          <td>{new Date(item.updated_at).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={pagination[currentTab.toLowerCase()].currentPage}
                    isLastPage={pagination[currentTab.toLowerCase()].isLastPage}
                    handlePageChange={this.handleFetchRecords}
                  />
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <div className="info-header">Record info:</div>
                <table className="table-responsive-sm quiz-record-table">
                  <tbody>
                    {this.getFields().map((field) => {
                      if (Array.isArray(currentRecord[field.name])) {
                        return (
                          <tr>
                            <td className="info-label">{field.text}</td>
                            <td>{currentRecord[field.name].length ? currentRecord[field.name].map((item) => (<div>{item}</div>)) : '-'}</td>
                          </tr>
                        );
                      }
                      if (['created_at', 'updated_at'].includes(field.name)) {
                        return (
                          <tr>
                            <td className="info-label">{`${field.text}: `}</td>
                            <td>{new Date(currentRecord[field.name]).toLocaleString()}</td>
                          </tr>
                        );
                      }
                      if (field.name === 'has_subscribed') {
                        return (
                          <tr>
                            <td className="info-label">{`${field.text}: `}</td>
                            <td>{currentRecord[field.name] ? 'Yes' : 'No'}</td>
                          </tr>
                        );
                      }

                      if (field.name.endsWith('_image')) {
                        return (
                          <tr>
                            <td className="info-label">{`${field.text}: `}</td>
                            <td><img alt="" src={currentRecord[field.name]} /></td>
                          </tr>
                        );
                      }

                      return (
                        <tr>
                          <td className="info-label">{`${field.text}: `}</td>
                          <td>{currentRecord[field.name] ? currentRecord[field.name] : '-'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-secondary btn-back"
                    onClick={() => this.handleStepChange(-1)}
                  >
                    Back
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
}

RecordsContainer.propTypes = {
  records: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
  canExport: PropTypes.bool,
};

RecordsContainer.defaultProps = {
  records: [],
  isLastPage: false,
  canExport: false,
};

export default RecordsContainer;
