import React from 'react';
import PropTypes from 'prop-types';

import Pagination from '../customElements/Pagination';
import CustomerInfoSection from '../common/CustomerInfoSection';
import api from '../../services/api';

class OrdersContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFetchOrders = this.handleFetchOrders.bind(this);
    this.handleOrderSelect = this.handleOrderSelect.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.state = {
      step: 1,
      customerInfo: null,
      orders: props.orders,
      searchText: urlParams.get('search') || '',
      lastSortKey: urlParams.get('order') || 'created_at desc',
      pagination: {
        currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
        isLastPage: props.isLastPage,
      },
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  handleStepChange(val = 1) {
    const { step } = this.state;
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchOrders(null, order);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleOrderSelect(order) {
    api.customers.subscriptionDetails({ id: order.customer_id })
      .then((res) => {
        const customerInfo = res;
        customerInfo.customer = order.customer;
        this.setState({ customerInfo }, this.handleStepChange);
      });
  }

  handleFetchOrders(newPage, newOrder) {
    const {
      pagination, lastSortKey, searchText,
    } = this.state;
    const page = newPage || pagination.currentPage || 1;
    const order = newOrder || lastSortKey;
    window.history.pushState({}, null, `?page=${page}&order=${order}&search=${searchText}`);

    api.orders.fetchOrders(page, order, searchText)
      .then((res) => this.setState({
        orders: res.orders,
        lastSortKey: order,
        pagination: {
          ...pagination,
          currentPage: page,
          isLastPage: res.is_last_page,
        },
      }));
  }

  render() {
    const {
      orders, step, pagination, searchText, customerInfo,
    } = this.state;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block search-container">
            <div>Orders</div>
            <form
              className={`search-block ${customerInfo ? 'hidden' : ''}`}
              onSubmit={(e) => {
                e.preventDefault();
                this.handleFetchOrders();
              }}
            >
              <input
                type="text"
                defaultValue={searchText}
                placeholder="Search..."
                id="search"
                onChange={this.handleChange('searchText')}
              />
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Choose order
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              2. Customer info
            </span>
          </div>
          {step === 1 && (
            <>
              <table className="table table-striped table-responsive-sm custom-table">
                <thead>
                  <tr>
                    <th scope="col" onClick={() => this.handleSort('id')}>Order id</th>
                    <th scope="col" onClick={() => this.handleSort('shopify_id')}>Shopify id</th>
                    <th scope="col">TK</th>
                    <th scope="col">SH</th>
                    <th scope="col">CO</th>
                    <th scope="col" onClick={() => this.handleSort('status')}>Status</th>
                    <th scope="col" onClick={() => this.handleSort('order_type')}>Type</th>
                    <th scope="col" onClick={() => this.handleSort('discount_code')}>Discount Code</th>
                    <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                    <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((item) => (
                    <tr className="custom-tr-selectable" key={item.id} onClick={() => this.handleOrderSelect(item)}>
                      <th scope="row">{item.id}</th>
                      <td>{item.shopify_id}</td>
                      <td>{item.tk_qty}</td>
                      <td>{item.shampoo_qty}</td>
                      <td>{item.conditioner_qty}</td>
                      <td>{item.custom_status}</td>
                      <td>{item.order_type}</td>
                      <td>{item.discount_code}</td>
                      <td>{new Date(item.created_at).toLocaleString()}</td>
                      <td>{new Date(item.updated_at).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={pagination.currentPage}
                isLastPage={pagination.isLastPage}
                handlePageChange={this.handleFetchOrders}
              />
            </>
          )}
          {step === 2 && (
            <div>
              {customerInfo && <CustomerInfoSection customerInfo={customerInfo} shouldRenderSubscriptionInfo={customerInfo.customer.subscription || customerInfo.customer.last_canceled_subscription} shouldRenderCurrentQuestionnaire={Object.keys(customerInfo.questionnaire).length} />}
              <div className="btn-group custom-btn-continue">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={() => this.handleStepChange(-1)}
                >
                  Back
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

OrdersContainer.propTypes = {
  orders: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
};

OrdersContainer.defaultProps = {
  orders: [],
  isLastPage: false,
};

export default OrdersContainer;
