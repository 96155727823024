import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import Logo from 'images/logo.svg';
import api from '../../services/api';

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleDownloadCSV = this.handleDownloadCSV.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.showExportNotification = this.showExportNotification.bind(this);
  }

  showExportNotification() {
    alert('The data export has been requested. You will receive an email with download URL when it is completed');
  }

  handleDownloadCSV(e, type) {
    e.preventDefault();
    if (type === 'orders') api.orders.downloadCSV('', 'orders').then(this.showExportNotification);
    if (type === 'quiz') api.quizRecords.downloadCSV('quiz').then(this.showExportNotification);
    if (type === 'scanner') api.quizRecords.downloadCSV('scanner').then(this.showExportNotification);
    if (type === 'activity_log') api.activityLog.downloadCSV().then(this.showExportNotification);
  }

  handleLogout(e) {
    e.preventDefault();

    api.auth.logout()
      .then(() => { window.location.href = '/'; });
  }

  disable2FA() {
    if (window.confirm('Disable 2FA!')) api.auth.disable2FA().then(() => { window.location.reload(); });
  }

  render() {
    const { user, canExportQuizRecords, canExportActivityLog } = this.props;

    return (
      <nav className="navbar navbar-expand-lg">
        <img src={Logo} alt="logo" className="nav-logo" />

        <button type="button" className="btn" id="menu-toggle">
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
            {user ? (
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {user.email}
                </a>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="#" onClick={(e) => this.handleDownloadCSV(e, 'orders')}>Export Orders to CSV</a>

                  { canExportQuizRecords && (
                    <>
                      <a className="dropdown-item" href="#" onClick={(e) => this.handleDownloadCSV(e, 'quiz')}>Export Quiz Records to CSV</a>
                      <a className="dropdown-item" href="#" onClick={(e) => this.handleDownloadCSV(e, 'scanner')}>Export Scanner Records to CSV</a>
                    </>
                  )}
                  { canExportActivityLog && (
                    <a className="dropdown-item" href="#" onClick={(e) => this.handleDownloadCSV(e, 'activity_log')}>Export Activity Log for last 30 days</a>
                  )}
                  <a className="dropdown-item" href="/discounts/generate_codes">Generate Discount Codes</a>
                  <a className="dropdown-item" href="/admin/users/edit">Change Password</a>
                  {user.otp_required_for_login ? (
                    <a className="dropdown-item" href="#" onClick={this.disable2FA}>Disable 2FA</a>
                  ) : (
                    <a className="dropdown-item" href="/two_factor_settings/new">Enable 2FA</a>
                  )}
                  <div className="dropdown-divider" />
                  <a className="dropdown-item" href="#" onClick={this.handleLogout}>Sign Out</a>
                </div>
              </li>
            ) : (
              <li className="nav-item">
                <a className="dropdown-item" href="/admin/users/sign_in">Sign In</a>
              </li>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  user: PropTypes.instanceOf(Object),
  canExportQuizRecords: PropTypes.bool.isRequired,
  canExportActivityLog: PropTypes.bool.isRequired,
};

Navbar.defaultProps = {
  user: null,
};

export default Navbar;
