import React from 'react';
import PropTypes from 'prop-types';

import Pagination from '../customElements/Pagination';
import CustomerInfoSection from '../common/CustomerInfoSection';
import ShopifyOrderLink from '../common/ShopifyOrderLink';
import api from '../../services/api';

class CustomersContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFetchCustomers = this.handleFetchCustomers.bind(this);
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this);
    this.handleOrderTabChange = this.handleOrderTabChange.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.state = {
      step: 1,
      customerInfo: null,
      customers: props.customers,
      searchText: urlParams.get('search') || '',
      lastSortKey: urlParams.get('order') || 'created_at desc',
      pagination: {
        currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
        isLastPage: props.isLastPage,
      },
      currentOrderTab: 'all',
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  handleStepChange(val = 1) {
    const { step } = this.state;
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchCustomers(null, order);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCustomerSelect(customer) {
    const { currentOrderTab } = this.state;

    api.customers.subscriptionDetails({ id: customer.id, tab: currentOrderTab, fetch_all: true })
      .then((res) => {
        const customerInfo = res;
        customerInfo.customer = customer;
        this.setState({ customerInfo }, this.handleStepChange);
      });
  }

  handleFetchCustomers(newPage, newOrder) {
    const {
      pagination, lastSortKey, searchText,
    } = this.state;
    const page = newPage || pagination.currentPage || 1;
    const order = newOrder || lastSortKey;
    window.history.pushState({}, null, `?page=${page}&order=${order}&search=${searchText}`);

    api.customers.fetchCustomers(page, order, searchText)
      .then((res) => this.setState({
        customers: res.customers,
        lastSortKey: order,
        pagination: {
          ...pagination,
          currentPage: page,
          isLastPage: res.is_last_page,
        },
      }));
  }

  handleOrderTabChange(tab) {
    const { customerInfo } = this.state;
    window.history.pushState({}, null, `?tab=${tab}`);

    api.customers.subscriptionDetails({ id: customerInfo.customer.id, tab })
      .then((res) => this.setState({ customerInfo: { ...customerInfo, orders: res.orders }, currentOrderTab: tab }));
  }

  render() {
    const {
      customers, step, pagination, searchText, customerInfo, currentOrderTab,
    } = this.state;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block search-container">
            <div>Customers</div>
            <form
              className={`search-block ${customerInfo ? 'hidden' : ''}`}
              onSubmit={(e) => {
                e.preventDefault();
                this.handleFetchCustomers();
              }}
            >
              <input
                type="text"
                defaultValue={searchText}
                placeholder="Search..."
                id="search"
                onChange={this.handleChange('searchText')}
              />
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Choose customer
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              2. Customer info
            </span>
          </div>
          {step === 1 && (
            <>
              <table className="table table-striped table-responsive-sm custom-table">
                <thead>
                  <tr>
                    <th scope="col" onClick={() => this.handleSort('id')}>ID</th>
                    <th scope="col" onClick={() => this.handleSort('email')}>Email</th>
                    <th scope="col" onClick={() => this.handleSort('first_name')}>First Name</th>
                    <th scope="col" onClick={() => this.handleSort('last_name')}>Last Name</th>
                    <th scope="col" onClick={() => this.handleSort('test_status')}>Test Status</th>
                    <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                    <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((item) => (
                    <tr className="custom-tr-selectable" key={item.id} onClick={() => this.handleCustomerSelect(item)}>
                      <th scope="row">{item.id}</th>
                      <td>{item.email}</td>
                      <td>{item.first_name}</td>
                      <td>{item.last_name}</td>
                      <td>{item.test_status_text}</td>
                      <td>{new Date(item.created_at).toLocaleString()}</td>
                      <td>{new Date(item.updated_at).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={pagination.currentPage}
                isLastPage={pagination.isLastPage}
                handlePageChange={this.handleFetchCustomers}
              />
            </>
          )}
          {step === 2 && (
            <div>
              {customerInfo && <CustomerInfoSection customerInfo={customerInfo} shouldRenderSubscriptionInfo={customerInfo.customer.subscription || customerInfo.customer.last_canceled_subscription} shouldRenderCurrentQuestionnaire={Object.keys(customerInfo.questionnaire).length} />}
              {/* Tabs */}
              <div className="custom-tabs">
                <div
                  id="tab-subscription"
                  onClick={() => this.handleOrderTabChange('subscription')}
                  className={currentOrderTab === 'subscription' ? 'active' : ''}
                >
                  Subscription
                </div>
                <div
                  id="tab-other"
                  onClick={() => this.handleOrderTabChange('other')}
                  className={currentOrderTab === 'other' ? 'active' : ''}
                >
                  Other
                </div>
                <div
                  id="tab-all"
                  onClick={() => this.handleOrderTabChange('all')}
                  className={currentOrderTab === 'all' ? 'active' : ''}
                >
                  ALL
                </div>
              </div>
              <table className="table table-striped table-responsive-sm custom-table mt-5">
                <thead>
                  <tr>
                    <th scope="col">Order</th>
                    <th scope="col">Products</th>
                    <th scope="col">Type</th>
                    <th scope="col">Carrier</th>
                    <th scope="col">Label Code</th>
                    <th scope="col">Created</th>
                    <th scope="col">Updated</th>
                    <th scope="col">Status Changed</th>
                    <th scope="col">Age</th>
                    { currentOrderTab === 'subscription' && <th scope="col">Total Order Value</th> }
                    { currentOrderTab === 'subscription' && <th scope="col">Discounts</th> }
                  </tr>
                </thead>
                <tbody>
                  {customerInfo.orders.map((item) => (
                    <tr key={item.id}>
                      <th scope="row"><ShopifyOrderLink shopifyId={item.shopify_id}>{`#${item.shopify_number}`}</ShopifyOrderLink></th>
                      <td>{`${item.shampoo_qty} x SH $${item.unit_costs.shampoo}\n${item.conditioner_qty} x CO $${item.unit_costs.conditioner}`}</td>
                      <td>{item.order_type}</td>
                      <td>{item.default_carrier}</td>
                      <td>{item.code}</td>
                      <td>{new Date(item.created_at).toLocaleString()}</td>
                      <td>{new Date(item.updated_at).toLocaleString()}</td>
                      <td>{new Date(item.status_changed_at).toLocaleString()}</td>
                      <td>{item.age}</td>
                      { currentOrderTab === 'subscription' && <td>{`$${(item.subtotal || 0).toFixed(2)}`}</td> }
                      { currentOrderTab === 'subscription' && <td>{`$${(item.discount_amount || 0).toFixed(2)}`}</td> }
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="btn-group custom-btn-continue">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={() => this.handleStepChange(-1)}
                >
                  Back
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

CustomersContainer.propTypes = {
  customers: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
};

CustomersContainer.defaultProps = {
  customers: [],
  isLastPage: false,
};

export default CustomersContainer;
