import React from 'react';
import PropTypes from 'prop-types';
import ActiveStorageProvider from 'react-activestorage-provider';
import ReactToPrint from 'react-to-print';
import { CSVLink } from 'react-csv';

import api from '../../services/api';
import { handleBeforePrint, codeWithoutSuffix, labelData } from '../../services/print';
import codeErrors from '../../services/codeValidators';
import LabelContainer from '../label/LabelContainer';
import callEvent from '../../services/events';
import { DISPLAY_CONFIRMATION_MODAL, DISPLAY_REASSIGN_TEST_KIT_MODAL } from '../../constants';
import Pagination from '../customElements/Pagination';
import CustomerInfoSection from '../common/CustomerInfoSection';
import { successToast } from '../../services/toast';
import ShopifyOrderLink from '../common/ShopifyOrderLink';

const dafaultImageData = {
  diameter: 0,
  microscopic: 1,
  usedForAvg: true,
};

class AddCustomerTestForm extends React.Component {
  constructor(props) {
    super(props);

    this.setUrlStep = this.setUrlStep.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.callConfirmationModal = this.callConfirmationModal.bind(this);
    this.callReassignTestKitModal = this.callReassignTestKitModal.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.handleTestKitSelect = this.handleTestKitSelect.bind(this);
    this.onPrintMount = this.onPrintMount.bind(this);
    this.calculatePAS = this.calculatePAS.bind(this);
    this.generateHairTexture = this.generateHairTexture.bind(this);
    this.generateMicroscopicScore = this.generateMicroscopicScore.bind(this);
    this.calculateHairTexture = this.calculateHairTexture.bind(this);
    this.calculateHairCondition = this.calculateHairCondition.bind(this);
    this.generateProteinLoss = this.generateProteinLoss.bind(this);
    this.calculateProteinLoss = this.calculateProteinLoss.bind(this);
    this.handleFetchFromS3 = this.handleFetchFromS3.bind(this);
    this.handleCustomerTestSave = this.handleCustomerTestSave.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handleImageDataChange = this.handleImageDataChange.bind(this);
    this.calculateAvgValueByCuticleData = this.calculateAvgValueByCuticleData.bind(this);
    this.handleFetchTestKits = this.handleFetchTestKits.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.generateCsvData = this.generateCsvData.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.defaultState = {
      step: 1,
      currentTab: urlParams.get('tab') || 'pending',
      searchText: urlParams.get('search') || '',
      selectedTestKit: null,
      searchedTestKit: null,
      codeError: null,
      proteinLoss: null,
      hairDiam: null,
      microscopicScore: 1,
      cuticleImages: [],
      cuticleImagesUrls: [],
      valuePAS: null,
      hairTexture: null,
      conc1: null,
      conc2: null,
      batchName: null,
      sampleNumber: null,
      invertProteinLoss: false,
      customerInfo: null,
      // sampleWeight: null,
      labels: [],
      readyForPrint: false,
      lastSortKey: urlParams.get('order') || 'created_at desc',
      testKits: props.testKits,
      saveClicked: false,
      cuticleImagesData: {
        image1: dafaultImageData,
        image2: dafaultImageData,
        image3: dafaultImageData,
        image4: dafaultImageData,
      },
      pagination: {
        published: {
          currentPage: urlParams.get('tab') === 'published' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'published' && urlParams.get('page') ? props.isLastPage : null,
        },
      },
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchTestKits(null, null, order);
  }

  // Step change
  handleStepChange(e, val = 1) {
    const { step } = this.state;
    if (e) e.preventDefault();
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  handleTestKitReassign = (data, id) => {
    api.testKits.reassign(id, data);
  }

  // input change
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Tabs
  handleTabChange(tab) {
    const { pagination } = this.state;
    this.setState({
      codeError: null,
      searchedTestKit: null,
    }, () => $('input#code').val(''));
    const page = pagination[tab] && pagination[tab].currentPage ? pagination[tab].currentPage : 1;
    this.handleFetchTestKits(page, tab);
  }

  // events
  callConfirmationModal(question, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: this.handlePublish,
      question,
      additionalData,
    });
  }

  callReassignTestKitModal(additionalData) {
    callEvent(
      DISPLAY_REASSIGN_TEST_KIT_MODAL,
      {
        handleSubmit: this.handleTestKitReassign,
        additionalData,
      },
    );
  }

  // code
  verifyCode(e) {
    const { currentTab } = this.state;
    const code = e.target.value;
    const error = codeErrors(code, ['-SS', '-PA']);
    if (error) return this.setState({ codeError: error });

    return api.addCustomerTests.codeVerification({
      code,
      status: 'Completed',
      customer_test_status_array: currentTab === 'pending' ? ['InProcess', 'Finished'] : ['Published'],
      postfix_array: ['-SS', '-PA'],
    }).then((res) => this.setState({
      codeError: res.err,
      searchedTestKit: res.test_kit_id ? res.test_kit : null,
    }));
  }

  handleTestKitSelect(item) {
    api.print.allLabels({ code: item.code, label_type: 'test_kit', postfix_array: ['PA'] })
      .then((res) => {
        const data = {
          labels: res.labels,
          selectedTestKit: item,
          hairDiam: item.customer_test.result_avg_hair_diam,
          hairTexture: this.calculateHairTexture(item.customer_test.result_avg_hair_diam),
          microscopicScore: item.customer_test.result_avg_microscopic_analysis_score,
          conc1: item.customer_test.conc1,
          conc2: item.customer_test.conc2,
          batchName: item.customer_test.batch_name,
          sampleNumber: item.customer_test.sample_number,
          invertProteinLoss: item.customer_test.invert_protein_loss,
          cuticleImages: item.customer_test.cuticle_images,
          cuticleImagesUrls: item.customer_test.cuticle_images,
        };
        if (Object.keys(item.customer_test.cuticle_images_data).length !== 0) {
          data.cuticleImagesData = item.customer_test.cuticle_images_data;
        }

        api.productFormulas.customerInfo({ customer_id: item.customer.id, order_id: item.order.id })
          .then((resp) => {
            data.customerInfo = resp;
            this.setState(data, () => {
              this.calculateProteinLoss();
              this.handleStepChange();
            });
          });
      });
  }

  // print
  onPrintMount() {
    this.setState({ readyForPrint: true });
  }

  // Step 2 - formula
  generateHairTexture() {
    const value = this.calculateAvgValueByCuticleData('diameter').toFixed(3);

    this.setState({
      hairTexture: this.calculateHairTexture(value),
      hairDiam: value,
    });
  }

  generateMicroscopicScore() {
    const value = this.calculateAvgValueByCuticleData('microscopic');

    this.setState({
      microscopicScore: Math.floor(value).toFixed(0),
    });
  }

  calculateHairTexture(value) {
    if (!value) return '';
    if (value < 65) return 'Fine';
    if (value > 85) return 'Coarse';
    return 'Medium';
  }

  generateProteinLoss = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    }, () => this.calculateProteinLoss());
  };

  calculateProteinLoss() {
    const { conc1, conc2 } = this.state;
    if (!conc1 || !conc2) {
      return this.setState({
        proteinLoss: null,
        valuePAS: null,
      });
    }

    const sampleWeightStd = 0.075;
    const avgConc = (parseFloat(conc1) + parseFloat(conc2)) / 2.0;
    const value = (avgConc * 0.02) / sampleWeightStd;
    return this.setState({
      proteinLoss: Math.round(value * 1000.0) / 1000.0,
      valuePAS: this.calculatePAS(value),
    });
  }

  calculatePAS(value) {
    const { invertProteinLoss } = this.state;
    if (!value) return '';
    let pas = invertProteinLoss ? (value * 10.0) / 24.0 : 10.0 - (value * 10.0) / 24.0;
    if (pas < 0) pas = 0.0; // add artificial limits
    if (pas > 10) pas = 10.0; // add artificial limits
    return Math.round(pas * 10.0) / 10.0;
  }

  calculateHairCondition() {
    const { microscopicScore, valuePAS } = this.state;
    const microscopicScaledScore = 10.0 - (microscopicScore - 1.0) * 2.5;
    const hairCondition = 0.9 * valuePAS + 0.1 * microscopicScaledScore;
    return Math.round(hairCondition * 10.0) / 10.0;
  }

  handleFetchFromS3(index) {
    const { selectedTestKit, cuticleImages, cuticleImagesUrls } = this.state;

    api.addCustomerTests.fetchS3Image({
      code: selectedTestKit.code,
      number: index + 1,
    })
      .then((res) => {
        if (!res.img_url) return alert('404: File not found');
        return this.setState({
          cuticleImages: cuticleImages.map((img, ind) => (ind === index ? res.img_url : img)),
          cuticleImagesUrls: cuticleImagesUrls.map((img, ind) => (ind === index ? res.img_url : img)),
        }, () => this.handleImageDataChange(index, res.img_diameter, 'diameter'));
      });
  }

  // Step 2 - Save
  handleCustomerTestSave(e) {
    if (e) e.preventDefault();

    const {
      selectedTestKit, proteinLoss, conc1, conc2,
      hairDiam, microscopicScore, cuticleImages,
      batchName, sampleNumber, invertProteinLoss,
      cuticleImagesData,
    } = this.state;

    const data = {
      test_kit_id: selectedTestKit.id,
      customer_id: selectedTestKit.customer.id,
      result_avg_protein_loss: proteinLoss,
      result_avg_hair_diam: hairDiam,
      result_avg_microscopic_analysis_score: microscopicScore,
      cuticle_images: cuticleImages,
      conc1,
      conc2,
      batch_name: batchName,
      sample_number: sampleNumber,
      invert_protein_loss: invertProteinLoss,
      cuticle_images_data: cuticleImagesData,
    };

    api.addCustomerTests.save(data)
      .then((res) => this.setState({
        selectedTestKit: res,
        cuticleImages: res.customer_test.cuticle_images,
        saveClicked: true,
      }, () => successToast('The record saved successfully')));
  }

  handlePublish() {
    const { selectedTestKit } = this.state;

    api.addCustomerTests.publish({ test_kit_id: selectedTestKit.id }).then(this.handleSuccess);
  }

  handleImageDataChange(index, value, field) {
    const { cuticleImagesData } = this.state;
    const newState = {
      cuticleImagesData: {
        ...cuticleImagesData,
        [`image${index + 1}`]: { ...cuticleImagesData[`image${index + 1}`], [field]: field === 'usedForAvg' ? value : parseFloat(value) || 0 },
      },
    };

    this.setState(newState, () => {
      if (['diameter', 'usedForAvg'].includes(field)) this.generateHairTexture();
      if (['microscopic', 'usedForAvg'].includes(field)) this.generateMicroscopicScore();
    });
  }

  calculateAvgValueByCuticleData(field) {
    const { cuticleImagesData } = this.state;
    let totalValue = 0;
    let count = 0;

    Object.entries(cuticleImagesData).forEach((hash) => {
      if (hash[1].usedForAvg) {
        totalValue += hash[1][field] || 0;
        count += 1;
      }
    });

    return totalValue / count;
  }

  handleFetchTestKits(newPage, newTab, newOrder) {
    const {
      currentTab, pagination, lastSortKey, searchText,
    } = this.state;
    const tab = newTab || currentTab;
    const page = newPage || (pagination[currentTab] ? pagination[currentTab].currentPage : 1);
    const order = newOrder || lastSortKey;
    window.history.pushState({}, null, `?tab=${tab}&page=${page}&order=${order}&search=${searchText}`);

    api.addCustomerTests.fetchTestKits(page, tab, order, searchText)
      .then((res) => this.setState({
        testKits: res.test_kits,
        currentTab: tab,
        lastSortKey: order,
        pagination: {
          ...pagination,
          [tab]: {
            ...pagination[tab],
            currentPage: page,
            isLastPage: res.is_last_page,
          },
        },
      }));
  }

  handleSuccess() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(this.defaultState, this.handleFetchTestKits);
  }

  generateCsvData() {
    const { testKits } = this.state;

    return [
      ['Test Kit', 'Email', 'First Name', 'Last Name', 'Order', 'Created', 'Updated', 'Status Changed', 'Age'],
      ...testKits.map((item) => [item.code, (item.customer || {}).email, (item.customer || {}).first_name, (item.customer || {}).last_name, `#${(item.order || {}).shopify_number}`, new Date(item.created_at).toLocaleString(), new Date(item.updated_at).toLocaleString(), new Date(item.status_changed_at).toLocaleString(), item.age]),
    ];
  }

  render() {
    const {
      step, valuePAS, hairTexture, microscopicScore,
      hairDiam, proteinLoss, cuticleImages, cuticleImagesUrls,
      codeError, searchedTestKit, selectedTestKit, searchText,
      conc1, conc2, labels, readyForPrint, currentTab,
      batchName, sampleNumber, testKits, invertProteinLoss,
      saveClicked, cuticleImagesData, pagination, customerInfo,
    } = this.state;
    const HairCondition = this.calculateHairCondition();
    const parsedTestKits = searchedTestKit ? [searchedTestKit, ...testKits.filter((i) => i.id !== searchedTestKit.id)] : testKits;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block search-container">
            <div>Test Data</div>
            <form
              className="search-block"
              onSubmit={(e) => {
                e.preventDefault();
                this.handleFetchTestKits();
              }}
            >
              <input
                type="text"
                defaultValue={searchText}
                placeholder="Search..."
                id="search"
                onChange={this.handleChange('searchText')}
              />
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Choose Customer Test
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              2. Test Data
            </span>
          </div>
          {/* Tabs */}
          <div className={step === 1 ? 'custom-tabs' : 'disabled custom-tabs'}>
            <div
              id="tab-pending"
              onClick={() => this.handleTabChange('pending')}
              className={currentTab === 'pending' ? 'active' : ''}
            >
              Pending
            </div>
            <div
              id="tab-published"
              onClick={() => this.handleTabChange('published')}
              className={currentTab === 'published' ? 'active' : ''}
            >
              Published
            </div>
          </div>
          {step === 1 && (
            <div>
              <div className="form-group input-medium">
                <label htmlFor="code">Code</label>
                <input
                  id="code"
                  name="code"
                  className="form-control"
                  onChange={this.verifyCode}
                />
                {codeError && (
                  <div data-testid="code-error" className="input-error">{codeError}</div>
                )}
              </div>
              <CSVLink className="csv-download" data={this.generateCsvData()}>Export as CSV</CSVLink>
              <table className="table table-striped table-responsive-sm custom-table mt-5">
                <thead>
                  <tr>
                    <th scope="col" onClick={() => this.handleSort('code')}>TEST KIT</th>
                    <th scope="col" onClick={() => this.handleSort('customer_id')}>Customer</th>
                    <th scope="col">Order</th>
                    <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                    <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                    <th scope="col" onClick={() => this.handleSort('status_changed_at')}>Status Changed</th>
                    <th scope="col" onClick={() => this.handleSort('status_changed_at')}>Age</th>
                  </tr>
                </thead>
                <tbody>
                  {parsedTestKits.map((item) => (
                    <tr
                      key={item.id}
                      className={`custom-tr-selectable ${searchedTestKit && item.id === searchedTestKit.id ? 'highlighted' : ''}`}
                      onClick={() => this.handleTestKitSelect(item)}
                    >
                      <td>{item.code}</td>
                      <td>{item.customer && `${item.customer.first_name} ${item.customer.last_name}\n ${item.customer.email}`}</td>
                      <td>{item.order && (<ShopifyOrderLink shopifyId={item.order.shopify_id}>{`#${item.order.shopify_number}`}</ShopifyOrderLink>)}</td>
                      <td>{new Date(item.customer_test.created_at).toLocaleString()}</td>
                      <td>{new Date(item.customer_test.updated_at).toLocaleString()}</td>
                      <td>{new Date(item.status_changed_at).toLocaleString()}</td>
                      <td>{item.age}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {currentTab !== 'pending' && (
                <Pagination
                  currentPage={pagination[currentTab].currentPage}
                  isLastPage={pagination[currentTab].isLastPage}
                  handlePageChange={this.handleFetchTestKits}
                />
              )}
            </div>
          )}
          {step === 2 && (
            <div>
              {customerInfo && <CustomerInfoSection customerInfo={customerInfo} shouldRenderCurrentQuestionnaire shouldRenderProductIssues />}
              <form onSubmit={this.handleCustomerTestSave} ref={(form) => { this.formStep2 = form; }}>

                {/* Average Diameter and Hair Texture */}
                <div className="form-group">
                  <div className="custom-2-blocks-row custom-2-blocks-row-header">
                    <div>
                      Average Diameter, um
                    </div>
                    <div>
                      Hair Texture
                    </div>
                  </div>
                  <div className="custom-2-blocks-row">
                    <div>{hairDiam}</div>
                    <div id="label-hairDiam">{hairTexture}</div>
                  </div>
                </div>

                {/* Protein Concentration inputs */}
                <div className="form-group">
                  <div className="custom-2-blocks-row custom-2-blocks-row-header">
                    <div>
                      Protein Concentration 1, ug/ml
                    </div>
                    <div>
                      Protein Concentration 2, ug/ml
                    </div>
                  </div>
                  <div className="custom-2-blocks-row">
                    <input
                      defaultValue={conc1}
                      onChange={this.generateProteinLoss('conc1')}
                      type="number"
                      step="0.0001"
                      min="0"
                      max="1000"
                      name="conc1"
                      className="form-control"
                      id="conc1"
                      data-testid="conc1"
                      disabled={currentTab === 'published'}
                    />
                    <input
                      defaultValue={conc2}
                      onChange={this.generateProteinLoss('conc2')}
                      type="number"
                      step="0.0001"
                      min="0"
                      max="1000"
                      name="conc2"
                      className="form-control"
                      id="conc2"
                      data-testid="conc2"
                      disabled={currentTab === 'published'}
                    />
                  </div>
                </div>

                {/* Batch name & Sample number */}
                <div className="form-group">
                  <div className="custom-2-blocks-row custom-2-blocks-row-header">
                    <div>
                      Batch Name
                    </div>
                    <div>
                      Sample Number
                    </div>
                  </div>
                  <div className="custom-2-blocks-row">
                    <input
                      defaultValue={batchName}
                      onChange={this.handleChange('batchName')}
                      type="text"
                      name="batchName"
                      className="form-control"
                      id="batchName"
                      data-testid="batchName"
                      disabled={currentTab === 'published'}
                    />
                    <input
                      defaultValue={sampleNumber}
                      onChange={this.handleChange('sampleNumber')}
                      type="text"
                      name="sampleNumber"
                      className="form-control"
                      id="sampleNumber"
                      data-testid="sampleNumber"
                      disabled={currentTab === 'published'}
                    />
                  </div>
                </div>

                {/* Calculated Protein Loss and PAS */}
                <div className="form-group">
                  <div className="custom-2-blocks-row custom-2-blocks-row-header">
                    <div>
                      Protein Loss, mg/g
                    </div>
                    <div>
                      Protein Analysis Score
                    </div>
                  </div>
                  <div className="custom-2-blocks-row">
                    <div id="value-proteinLoss">{proteinLoss}</div>
                    <div id="value-PAS">{valuePAS}</div>
                  </div>
                </div>

                {/* Invert */}
                <div className="form-group form-check form-check-ml">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="invertProteinLoss"
                    id="invertProteinLoss"
                    defaultChecked={invertProteinLoss}
                    onChange={() => this.setState((prevState) => ({ invertProteinLoss: !prevState.invertProteinLoss }), () => this.calculateProteinLoss())}
                  />
                  <label className="form-check-label" htmlFor="invertProteinLoss">
                    Invert Protein Loss
                  </label>
                </div>

                {/* Microscopy Score Slider */}
                <div className="form-group">
                  <div className="custom-2-blocks-row custom-2-blocks-row-header">
                    <div>
                      Average Microscopic Analysis Score
                    </div>
                    <div>
                      Hair Condition Score
                    </div>
                  </div>
                  <div className="custom-2-blocks-row">
                    <div id="value-microscopic-score">{microscopicScore}</div>
                    <div id="value-hair-condition">{HairCondition}</div>
                  </div>
                </div>
                <div>
                  {cuticleImagesUrls.map((i, index) => (
                    <div className="cuticle-image-block" key={index}>
                      <div className="cuticle-image-content">
                        <img src={i === '' ? 'https://topekacivictheatre.com/wp-content/uploads/2019/01/no-image.jpg' : i} alt="uploadedImage" />
                      </div>
                      {currentTab === 'pending' && (
                        <div className="cuticle-image-controls">
                          <div className="full-width">
                            <label htmlFor={`hairDiam${index}`}>
                              Diameter, um
                            </label>
                            <input
                              value={cuticleImagesData[`image${index + 1}`].diameter}
                              onChange={(e) => this.handleImageDataChange(index, e.target.value, 'diameter')}
                              type="number"
                              step="0.001"
                              min="0"
                              max="1000"
                              name={`hairDiam${index}`}
                              className="form-control"
                              id={`hairDiam${index}`}
                              data-testid="hairDiam"
                              disabled={currentTab === 'published'}
                            />
                          </div>
                          <div className="full-width mt-2">
                            <label htmlFor={`microscopic${index}`}>
                              Microscopy Score
                            </label>
                            <select
                              id={`microscopic${index}`}
                              name={`microscopic${index}`}
                              value={cuticleImagesData[`image${index + 1}`].microscopic}
                              onChange={(e) => this.handleImageDataChange(index, e.target.value, 'microscopic')}
                              disabled={currentTab === 'published'}
                            >
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                            </select>
                          </div>
                          <div className="full-width mt-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              name={`usedForAvg${index}`}
                              id={`usedForAvg${index}`}
                              defaultChecked={cuticleImagesData[`image${index + 1}`].usedForAvg}
                              onChange={() => this.handleImageDataChange(index, !cuticleImagesData[`image${index + 1}`].usedForAvg, 'usedForAvg')}
                              disabled={currentTab === 'published'}
                            />
                            <label className="form-check-label" htmlFor="invertProteinLoss">
                              Used For Avg
                            </label>
                          </div>
                          <div className="cuticle-image-input-container mt-4">
                            <ActiveStorageProvider
                              endpoint={{
                                path: `/customer_tests/upload_image?image_type=${index + 1}`,
                                model: 'CustomerTest',
                                attribute: `cuticle_image_${index + 1}`,
                                method: 'POST',
                              }}
                              onSubmit={(res) => {
                                this.setState({
                                  cuticleImages: cuticleImages.map((img, ind) => (ind === index ? res.image_code : img)),
                                  cuticleImagesUrls: cuticleImagesUrls.map((img, ind) => (ind === index ? res.image_url : img)),
                                });
                              }}
                              render={({ handleUpload, uploads, ready }) => (
                                <div>
                                  <input
                                    type="file"
                                    className="form-control"
                                    disabled={!ready}
                                    onChange={(e) => handleUpload(e.currentTarget.files)}
                                  />
                                  {uploads.map((upload) => {
                                    switch (upload.state) {
                                      case 'waiting':
                                        return (
                                          <p key={upload.id}>
                                            Waiting to upload
                                            {upload.file.name}
                                          </p>
                                        );
                                      case 'uploading':
                                        return (
                                          <p key={upload.id}>
                                            Uploading
                                            {upload.file.name}
                                            :
                                            {upload.progress}
                                            %
                                          </p>
                                        );
                                      case 'error':
                                        return (
                                          <p key={upload.id}>
                                            Error uploading
                                            {upload.file.name}
                                            :
                                            {upload.error}
                                          </p>
                                        );
                                      case 'finished':
                                        return (
                                          <p key={upload.id}>
                                            Finished uploading
                                            {upload.file.name}
                                          </p>
                                        );
                                      default:
                                        return 0;
                                    }
                                  })}
                                </div>
                              )}
                            />
                          </div>
                          <div className="cuticle-image-s3-container mt-2">
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={() => this.handleFetchFromS3(index)}
                            >
                              Link from S3
                            </button>
                          </div>
                          <div className="cuticle-image-delete-container mt-2">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => this.setState({
                                cuticleImages: cuticleImages.map((img, ind) => (ind === index ? '' : img)),
                                cuticleImagesUrls: cuticleImagesUrls.map((img, ind) => (ind === index ? '' : img)),
                              })}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="custom-hidden">
                  <LabelContainer
                    onMount={this.onPrintMount}
                    ref={(el) => { this.printContent = el; }}
                  />
                  {readyForPrint && (
                    <ReactToPrint
                      trigger={() => (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          id="print-trigger"
                        />
                      )}
                      content={() => this.printContent}
                    />
                  )}
                </div>
                <div className="btn-group custom-labels-print-btns mt-5">
                  {labels.map((label) => (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      key={label.code}
                      onClick={() => handleBeforePrint(label, codeWithoutSuffix(label.code), true, labelData(selectedTestKit.customer), 3)}
                    >
                      {label.name}
                    </button>
                  ))}
                </div>
                <div className="btn-group custom-btn-continue">
                  <button
                    type="button"
                    className="btn btn-secondary btn-back mr-2"
                    onClick={(e) => this.handleStepChange(e, -1)}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-back mr-2"
                    onClick={() => this.callConfirmationModal(currentTab === 'pending' ? 'Are you sure you want to publish this test report?' : 'Are you sure you want to hide this test report?')}
                    disabled={!selectedTestKit.customer_test || ((selectedTestKit.customer_test.status !== 'Finished' || !saveClicked || !customerInfo.env_data.uv) && currentTab === 'pending')}
                  >
                    {currentTab === 'pending' ? 'Publish' : 'Unpublish'}
                  </button>
                  <button
                    type="button"
                    className="btn btn-back btn-primary"
                    onClick={() => this.callReassignTestKitModal({
                      id: selectedTestKit.id,
                      customer: selectedTestKit.customer,
                    })}
                  >
                    Reassign
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={currentTab === 'published'}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}

AddCustomerTestForm.propTypes = {
  testKits: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
};

AddCustomerTestForm.defaultProps = {
  testKits: [],
  isLastPage: false,
};

export default AddCustomerTestForm;
